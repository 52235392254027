<template>
  <div>
    <!-- download-links -->
    <div class="download-search">
      <div class="row">
        <div class="col-md-4">
          <nav aria-label="breadcrumb">
            <ol class="report-bread breadcrumb">
              <li class="breadcrumb-item"><a href="#">Report</a></li>
              <li class="breadcrumb-item active" aria-current="page">
                Lead Report
              </li>
            </ol>
          </nav>
        </div>
        <div class="col-md-8">
          <div class="download-links">
            <ul class="d-flex align-items-center justify-content-end">
              <li class="timeline">
                <button
                  type="button"
                  v-bind:class="
                    agentActivity_.duration == 'today' ? 'current' : ''
                  "
                  v-on:click="funnelReport('today')"
                >
                  <span class="today">Today</span>
                </button>
              </li>
              <li class="timeline">
                <button
                  type="button"
                  v-bind:class="
                    agentActivity_.duration == 'year' ? 'current' : ''
                  "
                  v-on:click="funnelReport('year')"
                >
                  <span class="today">This Year</span>
                </button>
              </li>
              <li class="timeline datepicker-timeline">
                <button
                  type="button"
                  class="calender"
                  v-bind:class="
                    agentActivity_.duration == 'custom' ? 'current' : ''
                  "
                >
                  <date-range-picker
                    :opens="'left'"
                    :ranges="false"
                    :locale-data="{ format: 'yyyy-mm-dd' }"
                    v-model="agentActivity_.customDate"
                    @update="funnelReport('custom')"
                  ></date-range-picker
                  ><span class="calender">Custom Date</span>
                </button>
              </li>
              <li class="print">
                <a href="#" v-on:click="downloadIt('print')"
                  ><img
                    class="img-fluid print-icon"
                    src="../../assets/img/print.svg"
                    alt=""
                /></a>
              </li>
              <li>
                <a href="#" v-on:click="downloadIt('img')"
                  ><img class="img-fluid" src="../../assets/img/pdf.svg" alt=""
                /></a>
              </li>
              <li>
                <a href="#" v-on:click="downloadIt('pdf')"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/pdfp.svg"
                    alt=""
                /></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- download-links -->
    <div id="download_area">
      <!-- Funnel-area -->
      <div class="graph-area">
        <div class="grap-title">
          <div class="title-grap">
            <h4>Lead Funnel</h4>
          </div>
        </div>
        <div style="width: 200px; margin: 0px auto">
          <atom-spinner
            v-if="!loader"
            :animation-duration="1000"
            :size="60"
            :color="'#3151A1'"
          />
        </div>
        <div class="funnel-report" v-if="loader">
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="funnel-img">
                <img
                  class="img-fluid pull-right"
                  src="../../assets/img/funnel3.svg"
                  alt=""
                  style="margin-top: 74px"
                />
              </div>
            </div>
            <div class="col-lg-1"></div>
            <div class="col-lg-4 col-md-6">
              <div class="report-table">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th class="text-center">Lead</th>
                      <th class="text-center">%</th>
                    </tr>
                  </thead>
                  <tbody v-show="funneldata">
                    <tr v-for="(funnel, index) in funnel" :key="index">
                      <td>
                        <span
                          class="dots text-wrap"
                          :class="'dot' + Number(index + 1)"
                        ></span
                        >{{ funnel.title }}
                      </td>
                      <td class="text-center">{{ funnel.leads }}</td>
                      <td class="text-center">
                        {{ funnel.leads | getPercentage(funnel.received) }}%
                      </td>
                    </tr>
                    <!-- <tr>
                      <td><span class="dots dot2"></span>Contacted</td>
                      <td class="text-center">{{ funnel.total_contacted }}</td>
                      <td class="text-center">
                        {{
                          funnel.total_contacted
                            | getPercentage(funnel.total_received)
                        }}%
                      </td>
                    </tr>
                    <tr>
                      <td><span class="dots dot3"></span>Appointment</td>
                      <td class="text-center">
                        {{ funnel.total_appointments }}
                      </td>
                      <td class="text-center">
                        {{
                          funnel.total_appointments
                            | getPercentage(funnel.total_received)
                        }}%
                      </td>
                    </tr>
                    <tr>
                      <td><span class="dots dot4"></span>Apps Shown</td>
                      <td class="text-center">{{ funnel.total_apps_shown }}</td>
                      <td class="text-center">
                        {{
                          funnel.total_apps_shown
                            | getPercentage(funnel.total_received)
                        }}%
                      </td>
                    </tr>
                    <tr>
                      <td><span class="dots dot5"></span>Delivered</td>
                      <td class="text-center">{{ funnel.total_delivered }}</td>
                      <td class="text-center">
                        {{
                          funnel.total_delivered
                            | getPercentage(funnel.total_received)
                        }}%
                      </td>
                    </tr> -->
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-lg-4"></div>
          </div>
        </div>
      </div>
      <!-- Funnel-area -->

      <!-- analytics-area -->
      <div class="graph-area">
        <div class="grap-title">
          <div class="title-grap">
            <h4>Lead Analytics</h4>
          </div>
        </div>
        <div class="row" v-if="loader">
          <div class="col-lg-3 col-sm-6">
            <div class="analytics-graph">
              <div class="graph">
                <apexcharts
                  type="donut"
                  width="250"
                  :options="getChartOptions(analytics.data1.chartTitle)"
                  :series="analytics.data1.chartdata"
                ></apexcharts>
              </div>
            </div>
            <div class="funnel-list analytic-list">
              <table class="table">
                <thead>
                  <tr>
                    <th>Lead Type</th>
                    <th style="width: 96px">Lead Count</th>
                    <th style="text-align: center">%</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(san, indexx) in orderedleadsource1stgraph"
                    :key="indexx"
                  >
                    <td>
                      <span :class="'analytic sqr' + (indexx + 1)"></span
                      ><span>{{ san.title }}</span>
                    </td>
                    <td>{{ san.value }}</td>
                    <td>
                      ({{
                        san.value | getPercentage(analytics.data1.total_leads)
                      }}%)
                    </td>
                  </tr>
                  <tr>
                    <td>Total Leads</td>
                    <td>{{ analytics.data1.total_leads }}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            class="col-lg-3 col-sm-6"
            v-for="(analytic, index) in analytics.data"
            :key="index"
          >
            <div class="analytics-graph" v-if="index <= 2">
              <div class="graph">
                <apexcharts
                  width="250"
                  type="donut"
                  :options="getChartOptions(analytic[0].chart_label)"
                  :series="analytic[0].chart_data"
                ></apexcharts>
              </div>
            </div>
            <div v-if="index <= 2" class="funnel-list analytic-list">
              <table class="table">
                <thead>
                  <tr>
                    <th>{{ analytics.data1.type[index] }}</th>
                    <th style="width: 96px">Lead Count</th>
                    <th style="text-align: center">%</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(an, ind) in analytic" :key="an.ls_id">
                    <td>
                      <span class="analytic" :class="'sqr' + (ind + 1)"></span>
                      <span>{{ an.ls_name }}</span>
                    </td>
                    <td>{{ an.obtained_leads }}</td>
                    <td>
                      ({{ an.obtained_leads | getPercentage(an.total_leads) }}%)
                    </td>
                  </tr>

                  <tr>
                    <td>Total Leads</td>
                    <td>{{ analytic[0].sumleads }}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div style="width: 200px; margin: 0px auto">
          <atom-spinner
            v-if="!loader"
            :animation-duration="1000"
            :size="60"
            :color="'#3151A1'"
          />
        </div>
      </div>
      <!-- analytics-area -->

      <!-- Sources List -->
      <div class="graph-area">
        <div class="grap-title">
          <div class="title-grap">
            <h4>Lead Source</h4>
          </div>
        </div>
        <div class="leads-table-area report-table-area no-shadow">
          <table class="display" id="leads_sources_resports_table">
            <thead>
              <tr>
                <th class="text-center">Lead Source</th>
                <th class="text-center">Monthly Budget</th>
                <th class="text-center">Call / Lead counts</th>
                <th class="text-center">Cost / Lead</th>
                <th class="text-center">Cars Sold</th>
                <th class="text-center">Cost / Car Sold</th>
                <th class="text-center">Closing/Ratio</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(src, index) in orderedUsers" :key="index">
                <td class="text-center">{{ src.ls_name }}</td>
                <td class="text-center">${{ src.b_budget }}</td>
                <td class="text-center">{{ src.total_leads }}</td>
                <td class="text-center">
                  <span v-if="src.total_leads > 0">{{
                    (src.b_budget / src.total_leads) | roundoff
                  }}</span
                  ><span v-if="src.total_leads == 0">0</span>
                </td>
                <td class="text-center">{{ src.total_solds }}</td>
                <td class="text-center">
                  <span v-if="src.total_solds > 0">{{
                    (src.b_budget / src.total_solds) | roundoff
                  }}</span
                  ><span v-if="src.total_solds == 0">0</span>
                </td>
                <td class="text-center">{{ src.close }}%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Sources List -->
      <div class="">
        <div class="dataTables_wrapper no-footer">
          <div class="graph-area">
            <div class="grap-title">
              <div class="title-grap">
                <h4>Lead Funnel</h4>
              </div>
            </div>
            <table id="leadsFunnelReport" class="customReportsTable display">
              <thead>
                <tr>
                  <th style="width: 50%"></th>
                  <th class="text-center" style="width: 25%">Leads</th>
                  <th class="text-center" style="width: 25%">%</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="leadFunnel in leadsFunnelReport"
                  :key="leadFunnel.key"
                >
                  <td>{{ leadFunnel.name }}</td>
                  <td class="text-center">{{ leadFunnel.leads }}</td>
                  <td class="text-center">{{ leadFunnel.percentage }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="">
        <div class="dataTables_wrapper no-footer">
          <div class="graph-area">
            <div class="grap-title">
              <div class="title-grap">
                <h4>Social Media</h4>
              </div>
            </div>
            <table
              id="leadsSocialMediaReport"
              class="customReportsTable display"
            >
              <thead>
                <tr>
                  <th></th>
                  <th class="text-center" style="width: 25%">Leads</th>
                  <th class="text-center" style="width: 25%">%</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="leadSocial in leadsSocialMediaReport"
                  :key="leadSocial.key"
                >
                  <td>{{ leadSocial.name }}</td>
                  <td class="text-center">{{ leadSocial.leads }}</td>
                  <td class="text-center">{{ leadSocial.percentage }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="">
        <div class="dataTables_wrapper no-footer">
          <div class="graph-area">
            <div class="grap-title">
              <div class="title-grap">
                <h4>Third Party</h4>
              </div>
            </div>
            <table
              id="leadsThirdPartyReport"
              class="customReportsTable display"
            >
              <thead>
                <tr>
                  <th></th>
                  <th class="text-center" style="width: 25%">Leads</th>
                  <th class="text-center" style="width: 25%">%</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="leadThirdParty in leadsThirdPartyReport"
                  :key="leadThirdParty.key"
                >
                  <td>{{ leadThirdParty.name }}</td>
                  <td class="text-center">{{ leadThirdParty.leads }}</td>
                  <td class="text-center">{{ leadThirdParty.percentage }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div id="print_area" class="print_area">
      <img :src="print_src" style="max-width: 100%" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import PieChart from "../charts/PieChart.vue"
import VueApexCharts from "vue-apexcharts";
import $ from "jquery";
import moment from "moment";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import { orderBy } from "lodash";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import * as htmlToImage from "html-to-image";
//import { toJpeg } from 'html-to-image'
import jsPDF from "jspdf";
import { AtomSpinner } from "epic-spinners";
export default {
  name: "LeadsReports",
  components: { DateRangePicker, apexcharts: VueApexCharts, AtomSpinner },
  data() {
    return {
      funnel: {},
      funneldata: false,
      loader: false,
      agentActivity_: {
        duration: "year",
        customDate: {},
        id: 0,
        tm_id: 0,
        from: "",
        to: "",
      },
      analytics: { data1: [], data: [] },
      source: [],
      print_src: "",
      leadsFunnelReport: null,
      leadsAnalyticReport: null,
      leadsSocialMediaReport: null,
      leadsThirdPartyReport: null,
      leadsSourcesReportObject: null,
      leadsFunnelReportObject: null,
      leadsAnalyticReportObject: null,
      leadsSocialMediaReportObject: null,
      leadsThirdPartyReportObject: null,
      //chartdata: null,
      //chartlabel: null
    };
  },
  computed: {
    orderedUsers: function () {
      return orderBy(this.source, ["total_leads"], ["desc"]);
    },
    orderedleadsource1stgraph: function () {
      return orderBy(this.analytics.data1.data, ["value"], ["desc"]);
    },
    orderedleadsourceothergraph: function () {
      return orderBy(this.analytics.data, ["value"], ["desc"]);
    },
  },
  created() {
    this.funnelReport("year");
    // this.leadAnalyticsReport("year");
    // this.leadSourceReport("monthly", "create");
    // this.getFunnelReport();
    // this.getLeadsAnalycticReport();
    // this.getLeadsSocialMediaReport();
    // this.getLeadsThirdPartyReport();
  },
  beforeUpdate() {
    this.leadsFunnelReportObject.destroy();
    this.leadsSourcesReportObject.destroy();
    this.leadsAnalyticReportObject.destroy();
    this.leadsSocialMediaReportObject.destroy();
    this.leadsThirdPartyReportObject.destroy();
  },
  mounted() {},
  methods: {
    getChartOptions(chartTitle) {
      var opt = {
        chart: {
          type: "donut",
        },
        legend: {
          show: false,
        },
        labels: chartTitle,
        colors: [
          "#47169C",
          "#54B9DE",
          "#3B78FF",
          "#F99E5C",
          "#65DE8F",
          "#65DE8F",
          "#9966FF",
          "#2048d6",
          "#56d2ff",
        ],
        dataLabels: {
          enabled: false,
        },
      };
      return opt;
    },
    funnelReport(duration) {
      this.funneldata = false;
      this.loader = false;
      let startDate = this.agentActivity_.customDate.startDate;
      let endDate = this.agentActivity_.customDate.endDate;
      this.agentActivity_.from = moment(new Date(startDate)).format(
        "YYYY-MM-DD"
      );
      this.agentActivity_.to = moment(new Date(endDate)).format("YYYY-MM-DD");

      this.agentActivity_.duration = duration;
      axios
        .post("leads_report_funnel_stats", this.agentActivity_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.funneldata = true;
            var arr = [
              {
                title: "Received",
                leads: res.data.success.total_received,
                received: res.data.success.total_received,
              },
              {
                title: "Contacted",
                leads: res.data.success.total_contacted,
                received: res.data.success.total_received,
              },
              {
                title: "Scheduled",
                leads: res.data.success.total_appointments,
                received: res.data.success.total_received,
              },
              {
                title: "Demo",
                leads: res.data.success.demo,
                received: res.data.success.total_received,
              },

              {
                title: "Visits",
                leads: res.data.success.visits,
                received: res.data.success.total_received,
              },

              {
                title: "Write Ups",
                leads: res.data.success.desked,
                received: res.data.success.total_received,
              },
              {
                title: "Sold",
                leads: res.data.success.total_delivered,
                received: res.data.success.total_received,
              },
              {
                title: "Lost",
                leads: res.data.success.total_lost,
                received: res.data.success.total_received,
              },
            ];
            this.funnel = arr;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.leadAnalyticsReport(this.agentActivity_.duration);
      this.leadSourceReport(this.agentActivity_.duration, "create");
      this.getFunnelReport(this.agentActivity_.duration);
      this.getLeadsAnalycticReport(this.agentActivity_.duration);
      this.getLeadsSocialMediaReport(this.agentActivity_.duration);
      this.getLeadsThirdPartyReport(this.agentActivity_.duration);
    },
    leadAnalyticsReport(duration) {
      this.leadreportloader = false;
      let startDate = this.agentActivity_.customDate.startDate;
      let endDate = this.agentActivity_.customDate.endDate;
      this.agentActivity_.from = moment(new Date(startDate)).format(
        "YYYY-MM-DD"
      );
      this.agentActivity_.to = moment(new Date(endDate)).format("YYYY-MM-DD");

      this.agentActivity_.duration = duration;
      axios
        .post("leads_analytics", this.agentActivity_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.loader = true;
            // console.log(res.data.analytics);
            this.analytics.data = res.data.success;
            console.log(this.analytics.data);
            this.analytics.data1 = res.data.analytics;

            // res.data.analytics.data.data.forEach((value) => {
            //   console.log(value);
            // });
            // this.DonutchartOptions={
            //     labels:analytic[0].chart_label
            // }
          }
        })
        .catch((err) => {
          this.loader = true;
          console.log(err);
        });
    },
    leadSourceReport(duration, location = "") {
      console.log(location);
      let startDate = this.agentActivity_.customDate.startDate;
      let endDate = this.agentActivity_.customDate.endDate;
      this.agentActivity_.from = moment(new Date(startDate)).format(
        "YYYY-MM-DD"
      );
      this.agentActivity_.to = moment(new Date(endDate)).format("YYYY-MM-DD");
      this.agentActivity_.duration = duration;

      axios
        .post("leads_source_report", this.agentActivity_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          //console.log(res)
          if (res.data.success) {
            if ($.fn.DataTable.isDataTable("#leads_sources_resports_table")) {
              $("#leads_sources_resports_table").DataTable().destroy();
            }
            this.source = res.data.success;
            setTimeout(() => {
              if ($.fn.DataTable.isDataTable("#leads_sources_resports_table")) {
                $("#leads_sources_resports_table").DataTable().draw();
              } else {
                $("#leads_sources_resports_table").DataTable({
                  responsive: true,
                  paging: false,
                  bFilter: false,
                  ordering: false,
                  bInfo: false,
                });
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    downloadIt(type = "") {
      if (type == "img") {
        htmlToImage
          .toJpeg(document.getElementById("download_area"))
          .then(function (dataUrl) {
            var link = document.createElement("a");
            link.download = Date.now() + ".jpeg";
            link.href = dataUrl;
            link.click();
          });
      } else if (type == "pdf") {
        const doc = new jsPDF("p", "mm", "a4");
        var width = doc.internal.pageSize.getWidth();
        var height = doc.internal.pageSize.getHeight();
        htmlToImage
          .toJpeg(document.getElementById("download_area"))
          .then(function (dataUrl) {
            doc.addImage(dataUrl, "JPEG", 0, 0, width, height);
            doc.save();
          });
      } else if (type == "print") {
        let vm = this;
        htmlToImage
          .toJpeg(document.getElementById("download_area"))
          .then(function (dataUrl) {
            //console.log(dataUrl)
            vm.print_src = dataUrl;
            setTimeout(() => {
              vm.$htmlToPaper("print_area");
            }, 500);
          });
      }
    },
    getFunnelReport(duration) {
      let startDate = this.agentActivity_.customDate.startDate;
      let endDate = this.agentActivity_.customDate.endDate;
      this.agentActivity_.from = moment(new Date(startDate)).format(
        "YYYY-MM-DD"
      );
      this.agentActivity_.to = moment(new Date(endDate)).format("YYYY-MM-DD");
      this.agentActivity_.duration = duration;
      axios
        .post(
          axios.defaults.baseURL + "leads_analytics/funnel-report",
          this.agentActivity_,
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((response) => {
          if ($.fn.DataTable.isDataTable("#leadsFunnelReport")) {
            $("#leadsFunnelReport").DataTable().destroy();
          }
          this.leadsFunnelReport = response.data;

          setTimeout(() => {
            if ($.fn.DataTable.isDataTable("#leadsFunnelReport")) {
              $("#leadsFunnelReport").DataTable().draw();
            } else {
              $("#leadsFunnelReport").DataTable({
                responsive: true,
                paging: false,
                bFilter: false,
                ordering: false,
                bInfo: false,
              });
            }
          });
        });
    },
    getLeadsAnalycticReport(duration) {
      let startDate = this.agentActivity_.customDate.startDate;
      let endDate = this.agentActivity_.customDate.endDate;
      this.agentActivity_.from = moment(new Date(startDate)).format(
        "YYYY-MM-DD"
      );
      this.agentActivity_.to = moment(new Date(endDate)).format("YYYY-MM-DD");
      this.agentActivity_.duration = duration;
      axios
        .post(
          axios.defaults.baseURL + "leads_analytics/analytic-report",
          this.agentActivity_,
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((response) => {
          if ($.fn.DataTable.isDataTable("#leadsAnalyticReport")) {
            $("#leadsAnalyticReport").DataTable().destroy();
          }
          this.leadsAnalyticReport = response.data;
          setTimeout(() => {
            if ($.fn.DataTable.isDataTable("#leadsAnalyticReport")) {
              $("#leadsAnalyticReport").DataTable().draw();
            } else {
              $("#leadsAnalyticReport").DataTable({
                responsive: true,
                paging: false,
                bFilter: false,
                ordering: false,
                bInfo: false,
              });
            }
          });
        });
    },
    getLeadsSocialMediaReport(duration) {
      let startDate = this.agentActivity_.customDate.startDate;
      let endDate = this.agentActivity_.customDate.endDate;
      this.agentActivity_.from = moment(new Date(startDate)).format(
        "YYYY-MM-DD"
      );
      this.agentActivity_.to = moment(new Date(endDate)).format("YYYY-MM-DD");
      this.agentActivity_.duration = duration;
      axios
        .post(
          axios.defaults.baseURL + "leads_analytics/social-report",
          this.agentActivity_,
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((response) => {
          if ($.fn.DataTable.isDataTable("#leadsSocialMediaReport")) {
            $("#leadsSocialMediaReport").DataTable().destroy();
          }
          this.leadsSocialMediaReport = response.data;
          setTimeout(() => {
            if ($.fn.DataTable.isDataTable("#leadsSocialMediaReport")) {
              $("#leadsSocialMediaReport").DataTable().draw();
            } else {
              $("#leadsSocialMediaReport").DataTable({
                responsive: true,
                paging: false,
                bFilter: false,
                ordering: false,
                bInfo: false,
              });
            }
          });
        });
    },
    getLeadsThirdPartyReport(duration) {
      let startDate = this.agentActivity_.customDate.startDate;
      let endDate = this.agentActivity_.customDate.endDate;
      this.agentActivity_.from = moment(new Date(startDate)).format(
        "YYYY-MM-DD"
      );
      this.agentActivity_.to = moment(new Date(endDate)).format("YYYY-MM-DD");
      this.agentActivity_.duration = duration;
      axios
        .post(
          axios.defaults.baseURL + "leads_analytics/third-party-report",
          this.agentActivity_,
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((response) => {
          if ($.fn.DataTable.isDataTable("#leadsThirdPartyReport")) {
            $("#leadsThirdPartyReport").DataTable().destroy();
          }
          this.leadsThirdPartyReport = response.data;
          setTimeout(() => {
            if ($.fn.DataTable.isDataTable("#leadsThirdPartyReport")) {
              $("#leadsThirdPartyReport").DataTable().draw();
            } else {
              $("#leadsThirdPartyReport").DataTable({
                responsive: true,
                paging: false,
                bFilter: false,
                ordering: false,
                bInfo: false,
              });
            }
          });
        });
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/dataTables.css";
.table thead th {
  font-size: 13px;
  font-weight: 700;
}

.analytic-list span {
  font-size: 12px;
}
.lead-search .vue-daterange-picker {
  width: 100%;
}
.print {
  margin-right: 30px;
}
.download-text {
  margin-right: 4px;
}
.timeline {
  margin: 0px !important;
}
.datepicker-timeline {
  overflow: visible;
  margin-right: 5px !important;
}
.calender {
  position: relative;
}
.calender .vue-daterange-picker {
  position: absolute;
}
.calender .daterangepicker.opensleft {
  right: -100px;
}
</style>
