<template>
  <div id="canvas">
    <div class="download-search">
      <div class="row">
        <div class="col-md-6">
          <nav aria-label="breadcrumb">
            <ol class="report-bread breadcrumb">
              <li class="breadcrumb-item"><a href="#">Report</a></li>
              <li class="breadcrumb-item active" aria-current="page">
                Managers Top Down Report
              </li>
            </ol>
          </nav>
        </div>
        <div class="col-md-6">
          <div class="perfor-search">
            <form action="">
              <div class="form-row d-flex justify-content-end">
                <div class="form-group col-md-4">
                  <select
                    class="form-control"
                    v-model="month"
                    v-on:change="getManagersTopDownReport"
                  >
                    <option value="" disabled selected>Filter By Month</option>
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                </div>
                <div class="form-group col-md-4">
                  <select
                    class="form-control"
                    v-model="year"
                    v-on:change="getManagersTopDownReport"
                  >
                    <option value="" disabled selected>Filter By Year</option>
                    <option v-for="year in getYearList.slice(0, 3)" :key="year">
                      {{ year }}
                    </option>
                  </select>
                  <li
                    class="timeline"
                    style="
                      margin-top: 20px !important;
                      float: right;
                      list-style-type: none;
                    "
                  >
                    <button
                      @click="exportSalesRapData()"
                      :disabled="btnLoader"
                      type="button"
                    >
                      <span v-if="btnLoader"
                        ><span class="spinner-grow spinner-grow-sm"></span>
                        Loading...</span
                      >
                      <span v-if="!btnLoader"> Export CSV</span>
                    </button>
                  </li>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- ounter-grid -->
    <div class="">
      <div class="dataTables_wrapper no-footer">
        <div class="graph-area">
          <div class="grap-title">
            <div class="title-grap">
              <h4>Managers Top Down Report</h4>
            </div>
          </div>
          <div class="dataTables_wrapper no-footer">
            <table
              id="managersTopDownReportData"
              class="customReportsTable display dataTable no-footer"
              v-if="!loader"
            >
              <thead>
                <tr>
                  <th style="width: 20%; text-align: center">Day of Month</th>
                  <th style="width: 20%; text-align: center">
                    Today's Appointments
                  </th>
                  <th style="width: 20%; text-align: center">Today's Tasks</th>
                  <th style="width: 15%; text-align: center">Today's Alerts</th>
                  <th style="width: 15%; text-align: center">
                    Untouched Leads
                  </th>
                  <th style="width: 30%; text-align: center">
                    Total Left Over for Day
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="data in managersTopDownReportData" :key="data.key">
                  <td style="text-align: center">{{ data.day }}</td>
                  <td style="text-align: center">
                    {{ data.today_appointements }}
                  </td>
                  <td style="text-align: center">{{ data.today_tasks }}</td>
                  <td style="text-align: center">{{ data.today_alerts }}</td>
                  <td style="text-align: center">{{ data.leads_un_touch }}</td>
                  <td style="text-align: center">{{ data.left_over }}</td>
                </tr>
              </tbody>
            </table>
            <div>
              <img
                style=""
                class="center"
                v-if="loader"
                src="../../assets/img/Spinner.gif"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- graph-area -->
</template>
<script>
import axios from "axios";
// import VueApexCharts from "vue-apexcharts";
// import DateRangePicker from "vue2-daterange-picker";
// import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
// import axios from "axios";
// import moment from "moment";
// import jsPDF from "jspdf";
// import * as htmlToImage from "html-to-image";
import $ from "jquery";
// // import moment from "moment";
// // import jsPDF from "jspdf";
// // import html2canvas from "html2canvas";
export default {
  name: "ManagersTopDownReport",
  components: {},
  data() {
    return {
      btnLoader: false,
      export: false,
      year: new Date().getFullYear(),
      loader: true,
      month: null,
      managersTopDownReportData: null,
      managersTopDownReportDataObject: null,
    };
  },
  mounted() {
    console.log(this.month);
    console.log("component mounted");
    this.getManagersTopDownReport();
    this.currentMonth();
  },
  computed: {
    getYearList() {
      const currentYear = new Date().getFullYear();
      const range = (start, stop, step) =>
        Array.from(
          { length: (stop - start) / step + 1 },
          (_, i) => start + i * step
        );
      return range(currentYear, currentYear - 50, -1);
    },
  },
  updated() {
    // this.managersTopDownReportDataObject.dataTable();
  },
  // beforeUpdate() {
  //   // this.managersTopDownReportDataObject.delete();
  //   if (this.managersTopDownReportDataObject) {
  //     this.managersTopDownReportDataObject.fnDestroy();
  //   }
  // },
  methods: {
    exportSalesRapData() {
      this.export = true;
      this.btnLoader = true;
      axios
        .post(
          axios.defaults.baseURL + "managerstopdown",
          { export: this.export },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          this.export = false;
          this.btnLoader = false;
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "managertopdownreport.csv");
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
    currentMonth() {
      const currentDate = new Date();
      const monthNumber = currentDate.getMonth();
      this.month = "0" + (monthNumber + 1);
    },
    getManagersTopDownReport() {
      this.loader = true;
      axios
        .post(
          axios.defaults.baseURL + "managerstopdown",
          { month: this.month, year: this.year },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((response) => {
          if ($.fn.DataTable.isDataTable("#managersTopDownReportData")) {
            $("#managersTopDownReportData").DataTable().destroy();
          }
          this.managersTopDownReportData = response.data;
          this.loader = false;
          // setTimeout(() => {
          //   if ($.fn.DataTable.isDataTable("#managersTopDownReportData")) {
          //     $("#managersTopDownReportData").DataTable().draw();
          //   } else {
          //     $("#managersTopDownReportData").DataTable({
          //       responsive: true,
          //       paging: false,
          //       bFilter: false,
          //       ordering: false,
          //       bInfo: false,
          //     });
          //   }
          // });
        });
    },
  },
};
</script>
<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
}
.td {
  position: relative;
  right: -7px;
}
</style>
