<template>
  <DashboardContainer>
    <leads-reports v-if="this.$route.params.cate == 'leads'" />
    <agents-reports v-if="this.$route.params.cate == 'agents'" />
    <users-reports v-if="this.$route.params.cate == 'users'" />
    <inventory-report v-if="this.$route.params.cate == 'inventory'" />
    <Performance v-if="this.$route.params.cate == 'performanace'" />
    <ManagersTopDownReport
      v-if="this.$route.params.cate == 'managers-top-down'"
    />
    <BackIntoReport v-if="this.$route.params.cate == 'back-into-report'" />
    <AppointementByRap
      v-if="this.$route.params.cate == 'appointement-by-rap'"
    />
    <SalesRepReport v-if="this.$route.params.cate == 'sales-rep-report'" />
    <UserActivityReport
      v-if="this.$route.params.cate == 'user-activity-report'"
    />
    <LostReport v-if="this.$route.params.cate == 'lost-report'" />
    <DealerShipPerformance
      v-if="this.$route.params.cate == 'dealership-performance-report'"
    />
    <SourceSummaryReport
      v-if="this.$route.params.cate == 'source-summary-report'"
    />
    <BDCReport v-if="this.$route.params.cate == 'bdc-report'" />
    <ReponseTimeReport
      v-if="this.$route.params.cate == 'response-time-report'"
    />
    <SoldRecapReport v-if="this.$route.params.cate == 'sold-recap-report'" />
    <VehicleTrendsReport
      v-if="this.$route.params.cate == 'vehicle-trends-report'"
    />
    <InternetSalesReport v-if="this.$route.params.cate == 'internet-sales-report'"></InternetSalesReport>
    <SystemReport v-if="this.$route.params.cate == 'system-report'" />
    <SoldDealRecap v-if="this.$route.params.cate == 'desking-recap-report'" />
    <!-- <ActivityReports v-if="this.$route.params.cate == 'activity'"/>
        <SalesReports v-if="this.$route.params.cate == 'sales'"/>-->
  </DashboardContainer>
</template>
<script>
import DashboardContainer from "../containers/DashboardContainer";
import LeadsReports from "../components/reports/LeadsReports";
import AgentsReports from "../components/reports/AgentReports";
import UsersReports from "../components/reports/UsersReports";
import InventoryReport from "../components/reports/InventoryReport";
import Performance from "../components/reports/Performance";
import ManagersTopDownReport from "../components/reports/ManagersTopDownReport";
import BackIntoReport from "../components/reports/BackIntoReport";
import AppointementByRap from "../components/reports/AppointementByRap";
import SalesRepReport from "../components/reports/SalesRepReport";
import UserActivityReport from "../components/reports/UserActivityReport";
import LostReport from "../components/reports/LostReport";
import DealerShipPerformance from "../components/reports/DealerShipPerformance";
import SourceSummaryReport from "../components/reports/SourceSummaryReport";
import BDCReport from "../components/reports/BDCReport";
import ReponseTimeReport from "../components/reports/ReponseTimeReport";
import SoldRecapReport from "../components/reports/SoldRecapReport";
import VehicleTrendsReport from "../components/reports/VehicleTrendsReport";
import SystemReport from "../components/reports/SystemReport";
import SoldDealRecap from "../components/reports/SoldDealRecap";
import InternetSalesReport from "../components/reports/InternetSalesReport";
// import ActivityReports from '../components/reports/ActivityReports'
// import SalesReports from '../components/reports/SalesReports'
export default {
  name: "Reports",
  beforeCreate: function () {
    var auth = this.$storage.get("auth");
    if (!auth) {
      this.$router.push("/login");
    } else {
      if (!auth.token) {
        this.$router.push("/login");
      }
    }
  },
  components: {
    DashboardContainer,
    LeadsReports,
    AgentsReports,
    UsersReports,
    InventoryReport,
    Performance,
    ManagersTopDownReport,
    BackIntoReport,
    AppointementByRap,
    SalesRepReport,
    UserActivityReport,
    LostReport,
    DealerShipPerformance,
    SourceSummaryReport,
    BDCReport,
    ReponseTimeReport,
    SoldRecapReport,
    VehicleTrendsReport,
    SystemReport,
    SoldDealRecap,
    InternetSalesReport
    //ActivityReports,
    //SalesReports,
  },
};
</script>