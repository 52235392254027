<template>
  <div id="canvas">
    <div class="download-search lead-search-area">
      <div class="row">
        <div class="col-12">
          <nav aria-label="breadcrumb">
            <ol class="report-bread breadcrumb">
              <li class="breadcrumb-item"><a href="#">Report</a></li>
              <li class="breadcrumb-item active" aria-current="page">
                Performance Report
              </li>
            </ol>
          </nav>
        </div>
        <div class="col-md-4">
          <div class="lead-search sourceby">
            <div class="form-group">
              <!-- <select
                    class="form-control"
                    v-model="filters_.userid"
                    v-on:change="updateGraphs(filters_.duration)"
                  >
                    <option value="">Search by Agent or Team</option>
                    <option
                      v-for="agent in agentsOrTeam"
                      :key="agent.id"
                      :value="agent.id"
                    >
                      {{ agent.name }}
                    </option>
                  </select> -->

              <multi-select
                @change="updateGraphs(filters_.duration)"
                :searchable="true"
                id="a_usersads"
                textColumn="text"
                class="filter"
                placeholder="Select sources"
                v-model="filters_.userid"
                :options="agentsOrTeam"
              ></multi-select>
            </div>
          </div>
        </div>

        <div class="col-md-8">
          <div class="download-links">
            <ul class="d-flex align-items-center justify-content-end">
              <!---start--->
              <li class="timeline">
                <button
                  type="button"
                  v-on:click="updateGraphs('week')"
                  v-bind:class="filters_.duration == 'week' ? 'current' : ''"
                >
                  <span class="week">This Week</span>
                </button>
              </li>
              <li class="timeline">
                <button
                  type="button"
                  v-bind:class="filters_.duration == 'month' ? 'current' : ''"
                  v-on:click="updateGraphs('month')"
                >
                  <span class="today">This Month</span>
                </button>
              </li>
              <li class="timeline datepicker-timeline">
                <button
                  type="button"
                  class="calender"
                  v-bind:class="filters_.duration == 'custom' ? 'current' : ''"
                >
                  <date-range-picker
                    :opens="'left'"
                    :ranges="false"
                    :locale-data="{ format: 'yyyy-mm-dd' }"
                    v-model="filters_.customDate"
                    @update="updateGraphs('custom')"
                  ></date-range-picker
                  ><span class="calender">Custom Date</span>
                </button>
              </li>
              <li>
                <a href="#" v-on:click="downloadIt('print')"
                  ><img
                    class="img-fluid print-icon"
                    src="../../assets/img/print.svg"
                    alt=""
                /></a>
              </li>
              <li>
                <a href="#" v-on:click="downloadIt('pdf')"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/pdfp.svg"
                    alt=""
                /></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- ounter-grid -->
    <div class="graph-area">
      <div class="grap-title">
        <div class="title-grap">
          <h4>Walk Ins</h4>
        </div>
      </div>
      <!-- <apexchart
        type="bar"
        height="350"
        :options="chartOptions"
        :series="series"
      ></apexchart> -->
      <div class="inventory-graph">
        <apexchart
          type="bar"
          height="350"
          v-if="graph"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>

      <div class="Sold-Leads">
        <ul>
          <li><span class="walk-leads"></span>Walk In</li>
          <li><span class="walk-sold"></span>Sold</li>
        </ul>
      </div>

      <!-- report-tabs -->
    </div>

    <div class="graph-area">
      <div class="grap-title">
        <div class="title-grap">
          <h4>Phone Ups</h4>
        </div>
      </div>
      <!-- <apexchart
        type="bar"
        height="350"
        :options="chartOptions"
        :series="series"
      ></apexchart> -->
      <div class="inventory-graph">
        <apexchart
          v-if="phoneleads"
          type="bar"
          height="350"
          :options="phoneup"
          :series="phoneUpseries"
        ></apexchart>
      </div>

      <div class="Sold-Leads">
        <ul>
          <li><span class="phone-leads"></span>Phone Ups</li>
          <li><span class="phone-sold"></span>Sold</li>
        </ul>
      </div>

      <!-- report-tabs -->
    </div>

    <div class="graph-area">
      <div class="grap-title">
        <div class="title-grap">
          <h4>Internet Leads</h4>
        </div>
      </div>
      <!-- <apexchart
        type="bar"
        height="350"
        :options="chartOptions"
        :series="series"
      ></apexchart> -->
      <div class="inventory-graph">
        <apexchart
          type="bar"
          height="350"
          v-if="internetleadss"
          :options="internet"
          :series="internetSeries"
        ></apexchart>
      </div>
      <div class="Sold-Leads">
        <ul>
          <li><span class="digi-leads"></span>Internet Leads</li>
          <li><span class="digi-sold"></span>Sold</li>
        </ul>
      </div>
      <!-- graph-area -->
      <div id="print_area" class="print_area">
        <img :src="print_src" style="max-width: 100%" />
      </div>

      <!-- report-tabs -->
    </div>
  </div>
  <!-- graph-area -->
</template>
<script>
// import axios from "axios";
import VueApexCharts from "vue-apexcharts";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import axios from "axios";
import moment from "moment";
import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";
import $ from "jquery";
// import moment from "moment";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
import MultiSelect from "../helpers/MultiSelect";
export default {
  name: "performanacereport",
  components: { DateRangePicker, apexchart: VueApexCharts, MultiSelect },
  data() {
    return {
      agentsOrTeam: [],
      print_src: "",
      graph: false,
      phoneleads: false,
      internetleadss: false,
      filters_: {
        duration: "month",
        customDate: {},
        id: 0,
        userid: "",
        tm_id: 0,
        from: "",
        to: "",
      },
      walkinlabels: [],
      series: [],
      phoneUpseries: [],
      internetSeries: [],
      chartOptions: {
        chart: {
          type: "bar",
          height: 450,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "30%",
            startingShape: "rounded",
            endingShape: "rounded",
          },
        },
        colors: ["#793CE4", "#49CFFE"],
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "category",
          categories: [],
        },
      },
      phoneup: {
        chart: {
          type: "bar",
          height: 450,
        },
        plotOptions: {
          bar: {
            barHeight: "30%",
            horizontal: true,
            startingShape: "rounded",
            endingShape: "rounded",
          },
        },
        colors: ["#3452A2", "#DBE5FF"],
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
      },
      internet: {
        chart: {
          type: "bar",
          height: 450,
        },
        plotOptions: {
          bar: {
            barHeight: "30%",
            horizontal: true,
            startingShape: "rounded",
            endingShape: "rounded",
          },
        },
        colors: ["#E50102", "#FFC700"],
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
      },
    };
  },
  mounted() {
    // this.getWalkinReport();
    // this.phoneUp();
    // this.internetleads();
    this.getAgentsOrTeam();
    this.updateGraphs("month");
  },

  methods: {
    getAgentsOrTeam() {
      axios
        .get("all_employees/2", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          const data = res.data;
          if (data.success) {
            if (data.success.length > 0) {
              var dd = [];
              $.each(data.success, function (key, value) {
                dd.push({ id: value.id, text: value.name });
              });

              this.agentsOrTeam = dd;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    downloadIt(type = "") {
      if (type == "img") {
        htmlToImage
          .toJpeg(document.getElementById("canvas"))
          .then(function (dataUrl) {
            var link = document.createElement("a");
            link.download = Date.now() + ".jpeg";
            link.href = dataUrl;
            link.click();
          });
      } else if (type == "pdf") {
        const doc = new jsPDF("p", "mm", "a4");
        var width = doc.internal.pageSize.getWidth();
        var height = doc.internal.pageSize.getHeight();
        htmlToImage
          .toJpeg(document.getElementById("canvas"))
          .then(function (dataUrl) {
            doc.addImage(dataUrl, "JPEG", 0, 0, width, height);
            doc.save();
          });
      } else if (type == "print") {
        let vm = this;
        htmlToImage
          .toJpeg(document.getElementById("canvas"))
          .then(function (dataUrl) {
            //console.log(dataUrl)
            vm.print_src = dataUrl;
            setTimeout(() => {
              vm.$htmlToPaper("print_area");
            }, 500);
          });
      }
    },
    updateGraphPhone(val) {
      if (val == "week") {
        this.filters_.duration = "week";
        $("#phonemonth").removeClass("current");
        $("#phonecustom").removeClass("current");
        $("#phoneweek").addClass("current");
      } else if (val == "month") {
        this.filters_.duration = "month";
        $("#phoneweek").removeClass("current");
        $("#phonecustom").removeClass("current");
        $("#phonemonth").addClass("current");
      } else if (val == "custom") {
        this.filters_.duration = "custom";
        $("#phoneweek").removeClass("current");
        $("#phonemonth").removeClass("current");
        $("#phonecustom").addClass("current");
        let startDate = this.filters_.customDate.startDate;
        let endDate = this.filters_.customDate.endDate;
        if (
          startDate != undefined &&
          startDate != "undefined" &&
          startDate != null &&
          startDate != ""
        ) {
          this.filters_.from = moment(new Date(startDate)).format("YYYY-MM-DD");
        }
        if (
          endDate != undefined &&
          endDate != "undefined" &&
          endDate != null &&
          endDate != ""
        ) {
          this.filters_.to = moment(new Date(endDate)).format("YYYY-MM-DD");
        }
      }

      this.phoneUp();
    },

    updateGraphInternet(val) {
      if (val == "week") {
        this.filters_.duration = "week";
        $("#internetmonth").removeClass("current");
        $("#internetcustom").removeClass("current");
        $("#internetweek").addClass("current");
      } else if (val == "month") {
        $("#internetcustom").removeClass("current");
        $("#internetweek").removeClass("current");
        $("#internetmonth").addClass("current");
        this.filters_.duration = "month";
      } else if (val == "custom") {
        $("#internetmonth").removeClass("current");
        $("#internetweek").removeClass("current");
        $("#internetcustom").addClass("current");
        this.filters_.duration = "custom";
        let startDate = this.filters_.customDate.startDate;
        let endDate = this.filters_.customDate.endDate;
        if (
          startDate != undefined &&
          startDate != "undefined" &&
          startDate != null &&
          startDate != ""
        ) {
          this.filters_.from = moment(new Date(startDate)).format("YYYY-MM-DD");
        }
        if (
          endDate != undefined &&
          endDate != "undefined" &&
          endDate != null &&
          endDate != ""
        ) {
          this.filters_.to = moment(new Date(endDate)).format("YYYY-MM-DD");
        }
      }
      this.internetleads();
    },

    updateGraphswalk(val) {
      if (val == "week") {
        $("#walkmonth").removeClass("current");
        $("#walkcustom").removeClass("current");
        $("#walkweek").addClass("current");
      } else if (val == "month") {
        this.filters_.duration = "month";
        $("#walkweek").removeClass("current");
        $("#walkcustom").removeClass("current");
        $("#walkmonth").addClass("current");
      } else if (val == "custom") {
        $("#walkweek").removeClass("current");
        $("#walkcustom").addClass("current");
        $("#walkmonth").removeClass("current");
        this.filters_.duration = "custom";
        let startDate = this.filters_.customDate.startDate;
        let endDate = this.filters_.customDate.endDate;
        if (
          startDate != undefined &&
          startDate != "undefined" &&
          startDate != null &&
          startDate != ""
        ) {
          this.filters_.from = moment(new Date(startDate)).format("YYYY-MM-DD");
        }
        if (
          endDate != undefined &&
          endDate != "undefined" &&
          endDate != null &&
          endDate != ""
        ) {
          this.filters_.to = moment(new Date(endDate)).format("YYYY-MM-DD");
        }
      }
      this.getWalkinReport();
    },
    getWalkinReport() {
      axios
        .post("walkin_report", this.filters_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          console.log(res.data.success);

          if (res.data.success) {
            console.log(res.data.success.labels);
            this.chartOptions = {
              chart: {
                type: "bar",
                height: 450,
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  barHeight: "15%",
                  startingShape: "rounded",
                  endingShape: "rounded",
                },
              },
              colors: ["#793CE4", "#49CFFE"],
              legend: {
                show: false,
              },
              dataLabels: {
                enabled: false,
              },
              xaxis: {
                type: "category",
                categories: [],
              },
            };
            this.chartOptions.labels = res.data.success.labels;
            this.series = res.data.success.data;
            this.graph = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    phoneUp() {
      axios
        .post("phoneup", this.filters_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          console.log(res.data.success);

          if (res.data.success) {
            this.phoneup = {
              chart: {
                type: "bar",
                height: 450,
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  barHeight: "15%",
                  startingShape: "rounded",
                  endingShape: "rounded",
                },
              },
              colors: ["#793CE4", "#49CFFE"],
              legend: {
                show: false,
              },
              dataLabels: {
                enabled: false,
              },
              xaxis: {
                type: "category",
                categories: [],
              },
            };
            this.phoneup.labels = res.data.success.labels;
            this.phoneUpseries = res.data.success.data;
            this.phoneleads = true;
            // res.data.success.chart_data.forEach((value) => {
            //     console.log(value.data);
            //       this.series.push({data:value.data});

            // });
            //this.series =   res.data.success.data
            //this.chartOptions.xaxis.categories=res.data.success.labels
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    internetleads() {
      axios
        .post("internetleads", this.filters_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          console.log(res.data.success);

          if (res.data.success) {
            this.internet = {
              chart: {
                type: "bar",
                height: 450,
              },
              plotOptions: {
                bar: {
                  barHeight: "15%",
                  horizontal: true,
                  startingShape: "rounded",
                  endingShape: "rounded",
                },
              },
              colors: ["#E50102", "#FFC700"],
              legend: {
                show: false,
              },
              dataLabels: {
                enabled: false,
              },
              xaxis: {
                categories: [],
              },
            };
            this.internet.labels = res.data.success.labels;
            this.internetSeries = res.data.success.data;
            this.internetleadss = true;
            // res.data.success.chart_data.forEach((value) => {
            //     console.log(value.data);
            //       this.series.push({data:value.data});

            // });
            //this.series =   res.data.success.data
            //this.chartOptions.xaxis.categories=res.data.success.labels
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    updateGraphs(duration = "week") {
      console.log(duration);
      this.updateGraphPhone(duration);
      this.updateGraphInternet(duration);
      this.updateGraphswalk(duration);
    },
  },
};
</script>
<style >
.digi-leads {
  display: inline-block;
  height: 10px;
  width: 10px;
  background: #e50102;
  border-radius: 10px;
  margin-right: 8px;
}

.digi-sold {
  display: inline-block;
  height: 10px;
  width: 10px;
  background: #ffc700;
  border-radius: 1px;
  border-radius: 10px;
  margin-right: 8px;
}
.phone-leads {
  display: inline-block;
  height: 10px;
  width: 10px;
  background: #3452a2;
  border-radius: 1px;
  border-radius: 10px;
  margin-right: 8px;
}
.phone-sold {
  display: inline-block;
  height: 10px;
  width: 10px;
  background: #dbe5ff;
  border-radius: 1px;
  border-radius: 10px;
  margin-right: 8px;
}
.walk-sold {
  display: inline-block;
  height: 10px;
  width: 10px;
  background: #49cffe;
  border-radius: 1px;
  border-radius: 10px;
  margin-right: 8px;
}

.walk-leads {
  display: inline-block;
  height: 10px;
  width: 10px;
  background: #793ce4;
  border-radius: 1px;
  border-radius: 10px;
  margin-right: 8px;
}
.timeline {
  margin: 0px !important;
}
.datepicker-timeline {
  overflow: visible;
  margin-right: 5px !important;
}
.calender {
  position: relative;
}
.calender .vue-daterange-picker {
  position: absolute;
}
.calender .daterangepicker.opensleft {
  right: -100px;
}
.sourceby .customMS {
  width: 100%;
  margin-left: 15px;
}
.sourceby .customMS-drop h4 {
  border-radius: 30px;
  font-size: 12px;
  border: none;
  box-shadow: 0px 4px 8px rgba(49, 81, 161, 0.08);
}

.sourceby .customMS-search input {
  margin: 0;
}
</style>
