<template>
  <div id="download_area">
    <!-- download-links -->
    <div class="download-search">
      <div class="row">
        <div class="col-12">
          <nav aria-label="breadcrumb">
            <ol class="report-bread breadcrumb">
              <li class="breadcrumb-item"><a href="#">Report</a></li>
              <li class="breadcrumb-item active" aria-current="page">
                Competitive Report
              </li>
            </ol>
          </nav>
        </div>

        <div class="col-md-4">
          <div class="lead-search sourceby">
            <div class="form-group">
              <label>Agent</label>
              <!-- <SingleSelect
                v-on:change="getUsersReport('', 'activity', 'year')"
                :searchable="true"
                id="a_usersadss"
                textColumn="text"
                placeholder="Select Agent"
                v-model="selectedagent"
                :options="agentsOrTeam"
              ></SingleSelect> -->
              <multi-select
                v-on:change="getUsersReport('', 'activity', 'year')"
                :searchable="true"
                id="report"
                textColumn="text"
                :alreadySelected="selectedagent"
                :options="agentsOrTeam"
                v-model="selectedagent"
                placeholder="Select Agents"
              ></multi-select>
              <!-- <select class="form-control" id="by" v-model="filterSearch.by">
                        <option value="">Select Value</option>
                        <option v-for="source in LSources" :value="source.ls_id" :key="source.ls_id" >{{source.ls_name}}</option>
                    </select> -->
            </div>
          </div>
          <!-- <div  class="lead-search sourceby" >
                <div style="position: relative;bottom: 50px;">
                    <span>By</span>
                    <multi-select :searchable="true"  id="a_users" textColumn="text" placeholder="Select sources" v-model="filterSearch.by" :options="LSources" ></multi-select>
                </div>
            </div> -->
        </div>
        <div class="col-md-8">
          <div class="download-links">
            <ul class="d-flex align-items-center justify-content-end">
              <li class="timeline">
                <button
                  type="button"
                  v-bind:class="
                    agentActivity_.duration == 'today' ? 'current' : ''
                  "
                  v-on:click="getUsersReport('', 'activity', 'today')"
                >
                  <span class="today">Today</span>
                </button>
              </li>
              <li class="timeline">
                <button
                  type="button"
                  v-bind:class="
                    agentActivity_.duration == 'year' ? 'current' : ''
                  "
                  v-on:click="getUsersReport('', 'activity', 'year')"
                >
                  <span class="today">This Year</span>
                </button>
              </li>
              <li class="timeline datepicker-timeline">
                <button
                  type="button"
                  class="calender"
                  v-bind:class="
                    agentActivity_.duration == 'custom' ? 'current' : ''
                  "
                >
                  <date-range-picker
                    :opens="'left'"
                    :ranges="false"
                    :locale-data="{ format: 'yyyy-mm-dd' }"
                    v-model="agentActivity_.customDate"
                    @update="getUsersReport('', 'activity', 'custom')"
                  ></date-range-picker
                  ><span class="calender">Custom Date</span>
                </button>
              </li>
              <li>
                <a href="#" v-on:click="downloadIt('print')"
                  ><img
                    class="img-fluid print-icon"
                    src="../../assets/img/print.svg"
                    alt=""
                /></a>
              </li>
              <li>
                <a href="#" v-on:click="downloadIt('img')"
                  ><img class="img-fluid" src="../../assets/img/pdf.svg" alt=""
                /></a>
              </li>
              <li>
                <a href="#" v-on:click="downloadIt('pdf')"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/pdfp.svg"
                    alt=""
                /></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- download-links -->
    <vue-html2pdf
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1500"
      filename="usersreport"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      ref="html2Pdf"
      id="newprint"
    >
      <section slot="pdf-content">
        <!-- PDF Content Here -->

        <!-- graph-area -->

        <div class="graph-area">
          <div class="grap-title">
            <div class="title-grap">
              <h4>Marathon Race</h4>
            </div>
            <div class="touch-slod">
              <ul>
                <li>
                  <a href="#"
                    ><img
                      class="img-fluid stop-hand"
                      src="../../assets/img/stop.svg"
                      style="height: 18px"
                      alt=""
                    />Touches</a
                  >
                </li>
                <li>
                  <a href="#"
                    ><img
                      class="img-fluid"
                      src="../../assets/img/car2.svg"
                      alt=""
                    />Sold</a
                  >
                </li>
              </ul>
            </div>
          </div>

          <div class="graph-box">
            <div class="graph barchat">
              <!-- <horizontal-bar-chart :chartdata="userChartData.chart_data" :chartlabel="userChartData.labels" /> -->
              <div v-if="touchesLoaded">
                <apexchart
                  v-if="touchesLoaded"
                  type="bar"
                  height="260"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>
              </div>
              <div>
                <apexchart
                  v-if="agent"
                  type="bar"
                  height="260"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
        <!-- graph-area -->
        <!-- graph-area -->
        <div class="graph-area">
          <div class="grap-title">
            <div class="title-grap">
              <h4>Response Time</h4>
            </div>
          </div>

          <div class="graph-box">
            <div class="graph barchat2">
              <div v-if="ARTLoaded">
                <apexchart
                  v-if="ARTLoaded"
                  type="bar"
                  height="260"
                  :options="ARToptions"
                  :series="avgdata"
                ></apexchart>
              </div>

              <apexchart
                v-if="responseagent"
                type="bar"
                height="260"
                :options="ARToptions"
                :series="avgdata"
              ></apexchart>
            </div>
          </div>
        </div>
        <!-- graph-area -->
        <!-- Table Reports -->
        <div>
          <!-- leads-table-area  -->
          <div class="">
            <div class="dataTables_wrapper no-footer">
              <div class="graph-area">
                <div class="grap-title">
                  <div class="title-grap">
                    <h4>Touches & Engagement</h4>
                  </div>
                </div>
                <div class="dataTables_wrapper no-footer">
                  <table
                    id="marathonRaceTableData"
                    class="customReportsTable display dataTable no-footer"
                  >
                    <thead>
                      <tr>
                        <th class="text-center">Name</th>
                        <th class="text-center">Touches</th>
                        <th class="text-center">Engagement</th>
                        <th class="text-center">Engagement Rate</th>
                        <th class="text-center">Sold</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="marathon in orderedUsers" :key="marathon">
                        <td class="text-center">{{ marathon.agent_name }}</td>
                        <td class="text-center">{{ marathon.touch }}</td>
                        <td class="text-center">{{ marathon.engagement }}</td>
                        <td class="text-center">
                          {{ marathon.engagement_rate }}
                        </td>
                        <td class="text-center">{{ marathon.sold }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <!-- leads-table-area  -->
          <div class="">
            <div class="dataTables_wrapper no-footer">
              <div class="graph-area">
                <div class="grap-title">
                  <div class="title-grap">
                    <h4>Response Time By Rep.</h4>
                  </div>
                </div>
                <div class="dataTables_wrapper no-footer">
                  <table
                    id="agentsResponsesTable"
                    class="customReportsTable display dataTable no-footer"
                  >
                    <thead>
                      <tr>
                        <th class="text-center">Name</th>
                        <th class="text-center" style="width: 50%">
                          Response Time
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="agentResponse in ordereresponsetime"
                        :key="agentResponse.key"
                      >
                        <td class="text-center">
                          {{ agentResponse.agent_name }}
                        </td>
                        <td class="text-center">
                          {{ agentResponse.response_time }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <!-- leads-table-area  -->
          <div class="">
            <div class="dataTables_wrapper no-footer">
              <div class="graph-area">
                <div class="grap-title">
                  <div class="title-grap">
                    <h4>Closing Percenatage</h4>
                  </div>
                </div>
                <div class="dataTables_wrapper no-footer">
                  <table
                    id="closingPercentageReport"
                    class="customReportsTable display dataTable no-footer"
                  >
                    <thead>
                      <tr>
                        <th class="text-center">Name</th>
                        <th class="text-center">Leads</th>
                        <th class="text-center">Sold</th>
                        <th class="text-center">Closing %</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="closingPercentage in orderbyClosingPercentage"
                        :key="closingPercentage.key"
                      >
                        <td class="text-center">
                          {{ closingPercentage.agent_name }}
                        </td>
                        <td class="text-center">
                          {{ closingPercentage.leads }}
                        </td>
                        <td class="text-center">
                          {{ closingPercentage.sold }}
                        </td>
                        <td class="text-center">
                          {{ closingPercentage.percentage }} %
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="print_area" class="print_area">
          <img :src="print_src" style="max-width: 100%" />
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
// Data Tables
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
// import HorizontalBarChart from "../charts/HorizontalBarChart.vue"
import VueApexCharts from "vue-apexcharts";
import * as htmlToImage from "html-to-image";
// import jsPDF from "jspdf";
// import Autocomplete from "vuejs-auto-complete";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
// import SingleSelect from "../helpers/singleSelect";
import moment from "moment";
import VueHtml2pdf from "vue-html2pdf";
import { orderBy } from "lodash";
// import MultiSelect from "../helpers/MultiSelect";
import MultiSelect from "../helpers/MultiSelectWithCheckbox";
export default {
  name: "UsersReports",
  components: {
    VueHtml2pdf,
    DateRangePicker,
    apexchart: VueApexCharts,
    MultiSelect,
  },
  data() {
    return {
      selectedagent: [],
      agentsOrTeam: [],
      search: "",
      agent: false,
      responseagent: false,
      agentActivity_: {
        duration: "year",
        customDate: {},
        id: 0,
        tm_id: 0,
        from: "",
        to: "",
      },
      filters_: {
        to: "",
      },
      //userChartData:[],
      print_src: "",
      base_url: axios.defaults.baseURL,
      series: [],
      avgdata: [],
      ARTLoaded: false,
      touchesLoaded: false,
      ARToptions: {
        chart: {
          type: "bar",
        },
        plotOptions: {
          bar: {
            barHeight: "30%",
            horizontal: true,
            startingShape: "rounded",
            endingShape: "rounded",
          },
        },
        colors: ["#3151A1"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: [],
        },
        tooltip: {
          enabled: true,
          custom({ series, seriesIndex, dataPointIndex, w }) {
            return (
              '<div style="width: 100%">' +
              "<h6 style='padding:10px;font-weight:800'>" +
              w.globals.labels[dataPointIndex] +
              "</h6>" +
              "<span style='padding:10px'>Response Time in Minutes :" +
              " " +
              series[seriesIndex][dataPointIndex] +
              " Min</span>" +
              "</div>"
            );
          },
        },
      },
      chartOptions: {
        chart: {
          type: "bar",
        },
        plotOptions: {
          bar: {
            barHeight: "30%",
            horizontal: true,
            startingShape: "rounded",
            endingShape: "rounded",
          },
        },
        colors: ["#D5D8DF", "#3151A1"],
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
      },
      marathonRaceTableData: null,
      marathonRaceTableDataObject: null,
      agentsResponsesTable: null,
      agentsResponsesTableObject: null,
      closingPercentageReport: null,
      closingPercentageReportObject: null,
    };
  },

  created() {
    this.getUsersReport();
    this.getResponseTime();
    // this.getMarathonRaceTableData();
    // this.getAgentsResponseTimeTable();
    // this.getClosingPercentage();
    this.getAgentsOrTeam();
  },
  beforeUpdate() {
    this.marathonRaceTableDataObject.destroy();
    this.agentsResponsesTableObject.destroy();
    this.closingPercentageReportObject.destroy();
  },
  mounted() {
    let vm = this;
    this.$root.$on("edit_checkallpermisssions", function () {
      vm.selectedagent = vm.agentsOrTeam;
      vm.getUsersReport();
    });

    this.$root.$on("edit_unselectpermisssions", function () {
      vm.selectedagent = [];
      vm.getUsersReport();
    });
  },
  methods: {
    getUsersReport(result = "", type = "", duration = "year") {
      console.log(type);
      this.agentActivity_.duration = duration;
      this.agentActivity_.id = this.selectedagent;
      if (result.selectedObject) {
        if (
          result.selectedObject.user != undefined &&
          result.selectedObject.user != "undefined" &&
          result.selectedObject.user != null &&
          result.selectedObject.user != ""
        ) {
          this.agentActivity_.id = result.selectedObject.id;
          this.agentActivity_.tm_id = 0;
        }
        if (
          result.selectedObject.team != undefined &&
          result.selectedObject.team != "undefined" &&
          result.selectedObject.team != null &&
          result.selectedObject.team != ""
        ) {
          this.agentActivity_.id = 0;
          this.agentActivity_.tm_id = result.selectedObject.id;
        }
      }
      let startDate = this.agentActivity_.customDate.startDate;
      let endDate = this.agentActivity_.customDate.endDate;
      if (
        startDate != undefined &&
        startDate != "undefined" &&
        startDate != null &&
        startDate != ""
      ) {
        this.agentActivity_.from = moment(new Date(startDate)).format(
          "YYYY-MM-DD"
        );
      }
      if (
        endDate != undefined &&
        endDate != "undefined" &&
        endDate != null &&
        endDate != ""
      ) {
        this.agentActivity_.to = moment(new Date(endDate)).format("YYYY-MM-DD");
      }
      axios
        .post(
          "competitor_race",
          {
            customDate: this.agentActivity_.customDate,
            duration: this.agentActivity_.duration,
            from: this.agentActivity_.from,
            id: this.agentActivity_.id,
            tm_id: this.agentActivity_.tm_id,
            to: this.agentActivity_.to,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            if (this.agentActivity_.id) {
              this.chartOptions = {
                chart: {
                  type: "bar",
                },
                plotOptions: {
                  bar: {
                    barHeight: "30%",
                    horizontal: true,
                    startingShape: "rounded",
                    endingShape: "rounded",
                  },
                   fill: {
                  colors: ['#3151A1', '#D8DEEC']
                },
                },
                colors: ["#3151A1","#D8DEEC"],
               
                dataLabels: {
                  enabled: false,
                },
                legend: {
                  show: false,
                },
                xaxis: {
                  categories: [],
                },
                tooltip: {
                  enabled: true,
                },
              };
              this.chartOptions.labels = res.data.success.labels;
              this.series = res.data.success.data;
              this.agent = true;
              this.touchesLoaded = false;
            } else {
              this.chartOptions = {
                chart: {
                  type: "bar",
                },
                plotOptions: {
                  bar: {
                    barHeight: "30%",
                    horizontal: true,
                    startingShape: "rounded",
                    endingShape: "rounded",
                  },
                },
                colors: ["#D5D8DF", "#3151A1"],
                dataLabels: {
                  enabled: false,
                },
                legend: {
                  show: false,
                },
                xaxis: {
                  categories: [],
                },
                tooltip: {
                  enabled: true,
                },
              };
              this.chartOptions.labels = [];
              this.chartOptions.labels = res.data.success.labels;
              this.series = res.data.success.data;
              this.touchesLoaded = true;
              this.agent = false;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.getMarathonRaceTableData();
      this.getAgentsResponseTimeTable();
      this.getClosingPercentage();
      this.getResponseTime();
    },
    getResponseTime() {
      axios
        .post(
          "averageResponsetime",
          {
            customDate: this.agentActivity_.customDate,
            duration: this.agentActivity_.duration,
            from: this.agentActivity_.from,
            id: this.agentActivity_.id,
            tm_id: this.agentActivity_.tm_id,
            to: this.agentActivity_.to,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          console.log(res.data.success);

          if (res.data.success) {
            console.log();

            if (this.agentActivity_.id) {
              this.ARToptions = {
                chart: {
                  type: "bar",
                },
                plotOptions: {
                  bar: {
                    barHeight: "30%",
                    horizontal: true,
                    startingShape: "rounded",
                    endingShape: "rounded",
                  },
                },
                colors: ["#3151A1"],
                dataLabels: {
                  enabled: false,
                },
                legend: {
                  show: false,
                },
                xaxis: {
                  categories: [],
                },
                tooltip: {
                  enabled: true,
                  custom({ series, seriesIndex, dataPointIndex, w }) {
                    return (
                      '<div style="width: 100%">' +
                      "<h6 style='padding:10px;font-weight:800'>" +
                      w.globals.labels[dataPointIndex] +
                      "</h6>" +
                      "<span style='padding:10px'>Average Response Time :" +
                      " " +
                      series[seriesIndex][dataPointIndex] +
                      " Min</span>" +
                      "</div>"
                    );
                  },
                },
              };
              this.avgdata = res.data.success.data;
              this.ARToptions.labels = res.data.success.label;

              this.responseagent = true;
              this.ARTLoaded = false;
            } else {
              this.avgdata = res.data.success.data;
              this.ARToptions.labels = res.data.success.label;

              this.ARTLoaded = true;
              this.responseagent = false;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formattedAutocompleteDisplay(result) {
      return result.text;
    },
    downloadIt(type = "") {
      if (type == "img") {
        htmlToImage
          .toJpeg(document.getElementById("newprint"))
          .then(function (dataUrl) {
            var link = document.createElement("a");
            link.download = Date.now() + ".jpeg";
            link.href = dataUrl;
            link.click();
          });
      } else if (type == "pdf") {
        // const doc = new jsPDF();
        // htmlToImage
        //   .toJpeg(document.getElementById("download_area"))
        //   .then(function (dataUrl) {
        //     doc.addImage(dataUrl, "JPEG", 10, 10,);
        //     doc.save();
        //   });
        // const doc = new jsPDF("p", "mm", "a4");
        // var width = doc.internal.pageSize.getWidth();
        // var height = doc.internal.pageSize.getHeight();
        // htmlToImage
        //   .toJpeg(document.getElementById("download_area"))
        //   .then(function (dataUrl) {
        //     doc.addImage(dataUrl, "JPEG", 10, 10, width, height);
        //     doc.save();
        //   });
        this.$refs.html2Pdf.generatePdf();
      } else if (type == "print") {
        let vm = this;
        htmlToImage
          .toJpeg(document.getElementById("newprint"))
          .then(function (dataUrl) {
            //console.log(dataUrl)
            vm.print_src = dataUrl;
            setTimeout(() => {
              vm.$htmlToPaper("print_area");
            }, 500);
          });
      }
    },
    getMarathonRaceTableData() {
      axios
        .post(
          axios.defaults.baseURL + "competitor_race/marathon-race",
          {
            customDate: this.agentActivity_.customDate,
            duration: this.agentActivity_.duration,
            from: this.agentActivity_.from,
            id: this.agentActivity_.id,
            tm_id: this.agentActivity_.tm_id,
            to: this.agentActivity_.to,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((response) => {
          if ($.fn.DataTable.isDataTable("#marathonRaceTableData")) {
            $("#marathonRaceTableData").DataTable().destroy();
          }
          this.marathonRaceTableData = response.data;
          setTimeout(() => {
            if ($.fn.DataTable.isDataTable("#marathonRaceTableData")) {
              $("#marathonRaceTableData").DataTable().draw();
            } else {
              $("#marathonRaceTableData").DataTable({
                responsive: true,
                paging: false,
                bFilter: false,
                ordering: false,
                bInfo: false,
              });
            }
          });
        });
    },
    getAgentsResponseTimeTable() {
      axios
        .post(
          axios.defaults.baseURL + "competitor_race/response-time",
          {
            customDate: this.agentActivity_.customDate,
            duration: this.agentActivity_.duration,
            from: this.agentActivity_.from,
            id: this.agentActivity_.id,
            tm_id: this.agentActivity_.tm_id,
            to: this.agentActivity_.to,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((response) => {
          this.agentsResponsesTable = response.data;
        });
    },
    getClosingPercentage() {
      axios
        .post(
          axios.defaults.baseURL + "competitor_race/closing-percentage",
          {
            customDate: this.agentActivity_.customDate,
            duration: this.agentActivity_.duration,
            from: this.agentActivity_.from,
            id: this.agentActivity_.id,
            tm_id: this.agentActivity_.tm_id,
            to: this.agentActivity_.to,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((response) => {
          if ($.fn.DataTable.isDataTable("#closingPercentageReport")) {
            $("#closingPercentageReport").DataTable().destroy();
          }
          this.closingPercentageReport = response.data;
          setTimeout(() => {
            if ($.fn.DataTable.isDataTable("#closingPercentageReport")) {
              $("#closingPercentageReport").DataTable().draw();
            } else {
              $("#closingPercentageReport").DataTable({
                responsive: true,
                paging: false,
                bFilter: false,
                ordering: false,
                bInfo: false,
              });
            }
          });
        });
    },
    getAgentsOrTeam() {
      axios
        .get("search_agent_or_team", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.data) {
            if (res.data.data.length > 0) {
              var dd = [];
              $.each(res.data.data, function (key, value) {
                dd.push({
                  id: value.id,
                  text: value.text,
                });
              });
              this.agentsOrTeam = dd;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    autocompleteAuthHeaders() {
      return { Authorization: "Bearer " + this.$storage.get("auth").token };
    },
    orderedUsers: function () {
      return orderBy(this.marathonRaceTableData, "sold", "desc");
    },
    ordereresponsetime: function () {
      return orderBy(this.agentsResponsesTable, "response_time", "asc");
    },
    orderbyClosingPercentage: function () {
      return orderBy(this.closingPercentageReport, "leads", "desc");
    },
  },
};
</script>

<style scoped>
.user-report-search .vue-daterange-picker {
  width: 100%;
}
.user-report-search .vue-daterange-picker .reportrange-text {
  padding: 10px 8px;
}
.user-report-search .vue-daterange-picker i {
  padding-top: 1px !important;
}
.apexcharts-text.apexcharts-yaxis-label tspan {
  font-family: Muli, sans-serif !important;
  font-size: 11px;
  line-height: 1;
  color: #f6f7fa !important;
}
.timeline {
  margin: 0px !important;
}
.datepicker-timeline {
  overflow: visible;
  margin-right: 5px !important;
}
.calender {
  position: relative;
}
.calender .vue-daterange-picker {
  position: absolute;
}
.calender .daterangepicker.opensleft {
  right: -100px;
}
.td {
  position: relative;
  right: -7px;
}
.sourceby .customMS {
  width: 100%;
  margin-left: 15px;
}
.sourceby .customMS-drop h4 {
  border-radius: 30px;
  font-size: 12px;
  border: none;
  box-shadow: 0px 4px 8px rgba(49, 81, 161, 0.08);
}
.sourceby .show-options {
  top: 33px !important;
}
.sourceby .customMS-search input {
  margin: 0;
}
</style>
